import { createI18n } from "vue-i18n";

const userLocale =
    navigator.languages && navigator.languages.length
        ? navigator.languages[0]
        : navigator.language || "en-GB";

const i18n = createI18n({
    locale: userLocale,
    fallbackLocale: "en-GB",
    allowComposition: true,
    messages: {
        "en-GB": {
            alerts: {
                unexpectedErrorWithRefreshAction:
                    "We have encountered an unexpected issue. Please refresh the page to continue.",
                unexpectedErrorWithRetryAction:
                    "We have encountered an unexpected issue. Please try again.",
                validationError:
                    "Please make sure that all the required fields are filled out correctly before continuing.",
                sessionTimeout: "Your session has timed out.",
                successfulDownload: "The file has been exported successfully.",
                bankAccountValidation: "Please add at least one bank account.",
                bankAccountLimit:
                    "You've hit the limit on the number of bank accounts you can add.",
                existentStakeholder:
                    "A stakeholder with the same name already exists. Please modify the fields to continue.",
                removedStakeholder: "The stakeholder has been removed.",
                removedConsent: "The consent for {bank} has been revoked.",
                selectPayment: "Please select a payment to continue.",
                selectMandate: "Please select a mandate to continue.",
                selectAccount: "Please select an account to continue.",
                selectFile:
                    "No file was selected. Please select a file to continue.",
                selectDate: "Please select a date to continue.",
                mandatesUpdated: "The mandates have been updated.",
                paymentsUpdated: "Your payments have been updated.",
                instructionsUpdated: "The instructions have been updated.",
                removedInvalidReferences:
                    "The invalid references have been removed.",
                updatedCollectionDates: "The dates have been updated.",
                uploadedFile:
                    "The file has been uploaded. It might take a few minutes for the file contents to be processed.",
                invalidFiles: "Please ensure that the files are valid.",
                addedPaymentLink: "A new payment request link was added.",
                linkCopied: "Link copied.",
                keyCopied: "Key copied.",
                copyError: "Could not copy. Try again.",
                invalidEmail: "Please enter a valid email address to continue.",
                updatedEmail: "The email address has been updated.",
                successfulRefund: "Your refund request was successful.",
                updatedPostingPreferences:
                    "The posting preferences have been updated.",
                generatedApiKeys: "Successfully generated the API keys.",
                cannotModifyMandate:
                    "{failedCount} mandate{plural} cannot be modified. Please deselect them and try again.",
                cannotApproveMandateWithNotification:
                    "{failedCount} mandate{plural} cannot be approved with notification. Either the email address is missing for this record, or the collection date is too soon.",
                cannotModifyPayment:
                    "{failedCount} payment{plural} cannot be modified. Please deselect them and try again.",
                cannotApprovePaymentWithNotification:
                    "{failedCount} payment{plural} cannot be approved with notification. Either the email address is missing for this record, or the collection date is too soon.",
                authenticationRequired: "Permissions Updated: Action Required",
                accessUpdated:
                    "Your account permissions have been updated. To ensure these changes take effect, please log out and then log back in. This will refresh your access and apply the latest permissions.",
                selectCheckbox: "Please select the checkbox to confirm.",
                overdueInstruction:
                    "{count} {instruction} {verb} overdue. Please update the {date} to continue.",
                apiErrors: {
                    BAD_REQUEST:
                        "Invalid request: Please check your input and try again. Contact support if the issue persists.",
                    UNAUTHORIZED:
                        "Access to this resource is denied. Please log in again.",
                    FORBIDDEN:
                        "You do not have authorisation to view these resources. If you believe this is an error, please log out and log back in. For further assistance, please contact support.",
                    NOT_FOUND:
                        "The resource you were looking for could not be found.",
                    METHOD_NOT_ALLOWED:
                        "Action not allowed: This request method is not supported. Please contact support if you need assistance.",
                    INTERNAL_SERVER_ERROR_REFRESH:
                        "We have encountered an unexpected issue. Please refresh the page to continue.",
                    INTERNAL_SERVER_ERROR_RETRY:
                        "We have encountered an unexpected issue. Please try again.",
                    UNKNOWN_ERROR_REFRESH:
                        "We have encountered an unexpected issue. Please refresh the page to continue.",
                    UNKNOWN_ERROR_RETRY:
                        "We have encountered an unexpected issue. Please try again.",
                    TOO_MANY_REQUESTS:
                        "Too many requests, please try again later.",
                    NETWORK_ERROR:
                        "No internet connection. Please check your network connection.",
                    FILE_TOO_LARGE:
                        "File too large. Please upload a smaller one or contact support for assistance.",
                    SUCCESSFUL_DOWNLOAD:
                        "The file has been exported successfully.",
                    DUPLICATE_PAYMENT_REFERENCE:
                        "The payment reference is not unique.",
                    DUPLICATE_EMAIL_ADDRESS:
                        "This email address is already registered with another user account.",
                    TRANSACTIONS_DAILY_LIMIT_EXCEEDED:
                        "The transaction total exceeds the daily limit.",
                    EXPIRED_REGISTRATION_APPLICATION:
                        "The registration application is expired. Please contact support if you need assistance.",
                    DUPLICATE_ORGANISATION:
                        "An organisation with this name already exists. Please choose a different name or reach out to our support team for assistance.",
                    ORGANISATION_NOT_FOUND:
                        "The organisation could not be found. Please contact support if you need assistance.",
                    INVALID_APPROVER:
                        "You have already approved this instruction and cannot perform the second approval.",
                    EMAIL_REPORT:
                        "The report is too large to download immediately. You will receive an email with a download link in a few minutes once it's ready.",
                },
            },
        },
    },
    datetimeFormats: {
        "en-GB": {
            short: {
                year: "numeric",
                month: "short",
                day: "numeric",
            },
            long: {
                year: "numeric",
                month: "numeric",
                day: "numeric",
                hour: "numeric",
                minute: "numeric",
            },
        },
    },
});

export const translationExists = (key: string): boolean => {
    /**
     * Checks if the key exist in the list of internationalised messages.
     * It check in the "en-GB" locale for now as we do not have any other
     * translation languages enabled. Once we add more language support, we
     * can change the "en-GB" value to the user's locale
     * @param {string} key
     * @returns {boolean}
     */
    // TODO change "en-GB" to userLocale when we add support for more languages.
    return i18n.global.te(key, "en-GB");
};

export default i18n;
