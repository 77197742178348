import { changeRoute } from "@/router";
import { Roles } from "@/constants/access";

export const checkIfArrayElementsMatch = (
    existingRoles: string[],
    storedRoles: any,
) => {
    return existingRoles.some((r) => storedRoles.indexOf(r) >= 0);
};

export const redirectUserBasedOnRole = (userRole: any, origin: any) => {
    const admin = [Roles.ADMIN_ROLE];
    const school = [Roles.ORGANISATION_ROLE];

    if (checkIfArrayElementsMatch(admin, userRole)) {
        changeRoute("/admin/overview");
    } else if (checkIfArrayElementsMatch(school, userRole)) {
        // For school users, if an origin is provided we will redirect to it
        // when they are logged in. This is to enable the email download link
        // to work
        if (origin) {
            changeRoute(origin);
        } else {
            changeRoute("/school/overview");
        }
    } else if (userRole.length === 0) {
        changeRoute("/registration/company-details");
    } else {
        changeRoute("/unauthorised-access");
    }
};
